$black: #1e1f20;
$primary: #95c11f;
$danger: #ff453a;
$border: rgba(255, 255, 255, 0.1);

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/AvenirNextLTPro-UltLt.otf') format('opentype');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/AvenirNextLTPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/AvenirNextLTPro-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/AvenirNextLTPro-Demi.otf') format('opentype');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/AvenirNextLTPro-Bold.otf') format('opentype');
}
a {
  color: $primary;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}
.MuiAutocomplete-listbox{
  color: $black;
}

.MuiCalendarPicker-root {
  .PrivatePickersFadeTransitionGroup-root,
  .MuiPickersDay-root {
    color: $black;
  }
}

.required::after {
  content: '*';
  color: $danger;
}

.boxItem {
  border: 2px solid rgba($color: white, $alpha: 0.3);
  padding: 1.5rem;
  border-radius: 1rem;
  text-align: center;
  cursor: pointer;
  img {
    width: 56px;
    height: 56px;
    object-fit: contain;
    display: block;
    margin: 0 auto 1rem;
  }
}
.boxNotify, .boxProduct {
  background-color: rgba(58, 58, 60, 0.3);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 16px;
  min-height: 100px;
  img {
    display: block;
    width: 54px;
  }
}
.boxNotify{
  min-height: 30px;
  padding: 16px;
  margin-bottom: 16px;
  img{
    border-radius: 50%;
  }
}
.boxTicket {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 16px;

  .icon {
    display: block;
  }
}
.MuiBox-root.css-178yklu{
  a:not(:first-child){
    .boxTicket{
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.textError {
  color: $danger;
  display: block;
  margin-top: 0.5rem !important;
  font-size: 14px !important;
}
.borderBottom {
  border-bottom: 1px solid $border;
  padding-bottom: 16px;
}
.borderTop {
  border-top: 1px solid $border;
  padding-top: 16px;
}
.MuiCardHeader-title {
  color: white !important;
}
.boxFile {
  padding: 30px 20px;
  border: 1px dashed #4f4f4f;
  border-radius: 8px;
  cursor: pointer;
}
.boxPreviewFile {
  height: 140px;
  position: relative;
  img {
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
  video {
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
  .boxPreviewFileClose {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: $black;
    border-radius: 50%;
    cursor: pointer;
    i {
      display: block;
    }
  }
}
.cpr{
  text-align: center;
  padding: 5px 0;
  font-size: 11px;
  color: #e5e5e5;
}


@media screen and (max-width: 520px) {
  .appBar .appBarTitle{
    font-size: 18px;
  }
  
}
.messageContent{
  white-space: pre-line;
}
.messageAlert{
  padding: 12px;
  background-color: white;
  border-left: 8px $primary solid;
  color: $black;
  display: flex;
  align-items: center;
  gap: 10px;
}


.file-icon {
  width: 40px !important;
  height: 40px !important;
}

.file-details {
  display: grid;
}

.truncate-text {
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
}
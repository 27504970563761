@font-face {
  font-family: 'Icon';
  src: url('fonts/Icon.eot?qk6ona');
  src: url('fonts/Icon.eot?qk6ona#iefix') format('embedded-opentype'),
    url('fonts/Icon.ttf?qk6ona') format('truetype'), url('fonts/Icon.woff?qk6ona') format('woff'),
    url('fonts/Icon.svg?qk6ona#Icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-attach-square:before {
  content: '\e92b';
}
.icon-directbox-notif:before {
  content: '\e92a';
}
.icon-activity:before {
  content: '\e900';
}
.icon-add-circle:before {
  content: '\e901';
}
.icon-add-square:before {
  content: '\e902';
}
.icon-add:before {
  content: '\e903';
}
.icon-arrow-down:before {
  content: '\e904';
}
.icon-arrow-left:before {
  content: '\e905';
}
.icon-bell:before {
  content: '\e906';
}
.icon-buildings:before {
  content: '\e907';
}
.icon-card:before {
  content: '\e908';
}
.icon-category-2:before {
  content: '\e909';
}
.icon-close-circle:before {
  content: '\e90a';
}
.icon-close-square:before {
  content: '\e90b';
}
.icon-danger:before {
  content: '\e90c';
}
.icon-edit:before {
  content: '\e90d';
}
.icon-filter-search:before {
  content: '\e90e';
}
.icon-gallery-add:before {
  content: '\e90f';
}
.icon-house:before {
  content: '\e910';
}
.icon-info-circle:before {
  content: '\e911';
}
.icon-information:before {
  content: '\e912';
}
.icon-message-question:before {
  content: '\e913';
}
.icon-message-text:before {
  content: '\e914';
}
.icon-messages-3:before {
  content: '\e915';
}
.icon-messages:before {
  content: '\e916';
}
.icon-minus-cirlce:before {
  content: '\e917';
}
.icon-minus-square:before {
  content: '\e918';
}
.icon-minus:before {
  content: '\e919';
}
.icon-more-circle:before {
  content: '\e91a';
}
.icon-more-square:before {
  content: '\e91b';
}
.icon-more:before {
  content: '\e91c';
}
.icon-notification:before {
  content: '\e91d';
}
.icon-people:before {
  content: '\e91e';
}
.icon-personalcard:before {
  content: '\e91f';
}
.icon-profile-circle:before {
  content: '\e920';
}
.icon-search-normal:before {
  content: '\e921';
}
.icon-send-2:before {
  content: '\e922';
}
.icon-send:before {
  content: '\e923';
}
.icon-setting-4:before {
  content: '\e924';
}
.icon-tick-square:before {
  content: '\e925';
}
.icon-ticket:before {
  content: '\e926';
}
.icon-trash:before {
  content: '\e927';
}
.icon-user-square:before {
  content: '\e928';
}
.icon-user:before {
  content: '\e929';
}
